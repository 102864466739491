import React, { useState, useRef } from "react";
import Alert from "react-s-alert";

import {
  CustomNodeDefinition,
  CustomNodeProps,
  JsonData,
  JsonEditor,
} from "json-edit-react";
import { observer } from "mobx-react-lite";

import {
  getVideoUploadUrlJson,
  getFetchVideoBatchResultJson,
  postRequestVideoBatchJson,
  postCancelVideoBatchJson,
} from "../../external/api";
import useStore from "../../hooks/useStore";
import { BatchJob } from "../../stores/aiStore";
import UploadButton from "../Header/UploadButton";

const ParameterSpinner: React.FC<CustomNodeProps> = ({
  nodeData,
  value,
  setValue,
  handleEdit,
}) => {
  const getParams = (key: string) => {
    switch (key) {
      case "denoising":
      case "initial_noise_multiplier":
        return { min: 0, max: 1, step: 0.05 };
      case "fps":
        return { min: 5, max: 30, step: 5 };
      case "dilation":
        return { min: 0, max: 100, step: 5 };
      default:
        throw new Error("not supported");
    }
  };

  const { min, max, step } = getParams(nodeData.key as string);

  return (
    <div>
      <input
        type="number"
        value={value as string}
        min={min}
        max={max}
        step={step}
        style={{ color: "black" }}
        onChange={(e) => {
          setValue(Number(e.target.value));
        }}
        onBlur={() => {
          handleEdit();
        }}
      />
    </div>
  );
};

const TimeSpinner: React.FC<CustomNodeProps> = ({
  value,
  setValue,
  handleEdit,
}) => {
  const parseSeconds = (time: string): number => {
    const [minutes, seconds] = time.split(":").map(Number);
    return (minutes || 0) * 60 + (seconds || 0);
  };

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const totalSeconds = Number(e.target.value);
    setValue(formatTime(totalSeconds));
  };

  return (
    <div>
      <input
        type="text"
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          setValue(formatTime(parseSeconds(value as string)));
          handleEdit();
        }}
        style={{ color: "black" }}
      />
      <input
        type="number"
        min="0"
        max="1440"
        step="1"
        value={parseSeconds(value as string)}
        onChange={handleChange}
        onBlur={() => {
          handleEdit();
        }}
        style={{ color: "black", marginLeft: "5px" }}
      />
    </div>
  );
};

const ParameterDropdown: React.FC<CustomNodeProps> = ({
  nodeData,
  value,
  setValue,
  handleEdit,
}) => {
  const options =
    nodeData.path[0] === "pass1"
      ? ["FACE", "FACE_SKIN", "FACE_OUTLINE", "HAIR"]
      : [
          "BODY",
          "BODY_SKIN",
          "EX_BODY_SKIN",
          "ALL_EXCEPT_FACE",
          "ALL_EXCEPT_FACE_SKIN",
          "ALL_EXCEPT_FACE_AND_CLOTHES",
          "ALL_EXCEPT_FACE_SKIN_AND_CLOTHES",
          "BACKGROUND",
        ];

  return (
    <div>
      <select
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          handleEdit();
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const GeneralCheckBox: React.FC<CustomNodeProps> = ({
  value,
  setValue,
  handleEdit,
}) => {
  return (
    <input
      className="jer-input-boolean"
      type="checkbox"
      checked={value as boolean}
      onChange={() => setValue(!value)}
      onBlur={() => {
        handleEdit();
      }}
    />
  );
};

const GeneralTextField: React.FC<CustomNodeProps> = ({
  value,
  setValue,
  handleEdit,
}) => {
  return (
    <input
      type="text"
      value={value as string}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        handleEdit();
      }}
      style={{ color: "black" }}
    />
  );
};

const customNodeDefinitions: CustomNodeDefinition[] = [
  {
    condition: ({ key }) =>
      ["denoising", "initial_noise_multiplier", "fps", "dilation"].includes(
        key as string
      ),
    element: ParameterSpinner,
    showOnView: true,
    showOnEdit: true,
  },
  {
    condition: ({ key }) => ["start", "end"].includes(key as string),
    element: TimeSpinner,
    showOnView: true,
    showOnEdit: true,
  },
  {
    condition: ({ key }) => key === "body_part",
    element: ParameterDropdown,
    showOnView: true,
    showOnEdit: true,
  },
  {
    condition: ({ value }) => typeof value === "boolean",
    element: GeneralCheckBox,
    showOnView: true,
    showOnEdit: true,
  },
  {
    condition: ({ value }) =>
      typeof value === "string" || typeof value === "number",
    element: GeneralTextField,
    showOnView: true,
    showOnEdit: true,
  },
];

const ToolbarBatch: React.FC = observer(() => {
  const { aiStore, authStore, uiStore } = useStore();
  const [processLog, setProcessLog] = useState<any>({
    message: "no job selected",
  });

  const uploadVideo = async (files: File[]) => {
    try {
      uiStore.setInprogress(true);
      const { uploadUrl, objKey } = await getVideoUploadUrlJson();
      const theFile = files[0];

      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: theFile,
        headers: {
          "Content-Type": theFile.type,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          `Failed to upload video. Status: ${response.status}, Body: ${errorText}`
        );
        Alert.error("Failed to upload video");
      } else {
        aiStore.addOrUpdateObjKeyForVideoBatch(theFile.name, objKey);
        Alert.success("Video uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      Alert.error("An error occurred while uploading the video");
    } finally {
      uiStore.setInprogress(false);
    }
  };

  const changeTargetVideo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    aiStore.setLatestObjKeyForVideoBatch(event.target.value);
  };

  const requestBatchJob = async () => {
    if (!aiStore.latestObjKeyForVideoBatch) {
      Alert.error("No video uploaded yet");
      return;
    }
    uiStore.setInprogress(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const script: any = aiStore.videoBatchScript;
    console.log(script);
    const scriptToRecord = JSON.stringify(script);
    const scriptTimeConverted = {
      ...script,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      start: script.start
        .split(":")
        .reduce((m: number, s: string) => m * 60 + parseFloat(s), 0),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      end: script.end
        .split(":")
        .reduce((m: number, s: string) => m * 60 + parseFloat(s), 0),
    };

    const response = await postRequestVideoBatchJson(
      aiStore.latestObjKeyForVideoBatch,
      scriptTimeConverted
    );
    uiStore.setInprogress(false);
    console.log(response);
    aiStore.addJob({ ...response, script: scriptToRecord });
    Alert.success("Job requested successfully");
  };

  const fetchMonitorImageAsDataURL = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const cancelJob = async (job: BatchJob) => {
    uiStore.setInprogress(true);
    const response = await postCancelVideoBatchJson(job.jobId);
    uiStore.setInprogress(false);
    aiStore.updateJob(response);
  };

  const fetchResult = async (job: BatchJob) => {
    uiStore.setInprogress(true);
    const response = await getFetchVideoBatchResultJson(job.jobId);
    uiStore.setInprogress(false);
    aiStore.updateJob(response);

    if (response.status === "processing") {
      const originalImageUrl = await fetchMonitorImageAsDataURL(
        job.originalImageUrl
      );
      const monitorImageUrl = await fetchMonitorImageAsDataURL(
        job.monitorImageUrl
      );

      if (monitorImageUrl) {
        aiStore.setTargetImageData(
          monitorImageUrl,
          "video-batch-monitor",
          originalImageUrl
        );
      }
    }
  };

  const displayLog = (job: BatchJob) => {
    console.log(job);
    if (job.log) {
      const log = { job_id: job.jobId, ...JSON.parse(job.log) };
      setProcessLog(log);
    }
  };

  const importScript = (job: BatchJob) => {
    if (job.script) {
      aiStore.setVideoBatchScript(JSON.parse(job.script));
    }
  };

  const deriveTime = (jobId: string) => {
    return jobId.slice(-6).replace(/(\d{2})(\d{2})(\d{2})/, "$1:$2:$3");
  };

  return (
    <div className="toolbar__content">
      {authStore.canUseBatch() ? (
        <>
          <div className="preview__header">
            <UploadButton
              onFileSelected={uploadVideo}
              allowDirectory={false}
              assumeVideo={true}
            />
            &nbsp;&nbsp;&nbsp;
            {/* <span>{aiStore.latestObjKeyForVideoBatch}</span> */}
            <select
              className="uploaded-video"
              value={aiStore.latestObjKeyForVideoBatch}
              onChange={changeTargetVideo}
            >
              {Object.entries(aiStore.objKeysForVideoBatch).map(
                ([fileName, objKey]) => (
                  <option key={objKey} value={objKey}>
                    {fileName.length > 30
                      ? `${fileName.substring(0, 30)}...`
                      : fileName}
                  </option>
                )
              )}
            </select>
          </div>
          <div
            className="toolbar__option"
            onClick={() => {
              requestBatchJob();
            }}
          >
            <p>Request new job</p>
          </div>

          <table className="batch-job">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Progress</th>
                <th></th>
                <th></th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {aiStore.videoBatchJobs.map((job, index) => (
                <tr key={job.jobId}>
                  <td>{deriveTime(job.jobId)}</td>
                  <td>{job.status}</td>
                  <td>
                    {job.result ? (
                      <a href={job.result} className="download-link">
                        DL
                      </a>
                    ) : (
                      ""
                    )}{" "}
                    {job.progress ? `${job.progress}%` : ""}
                  </td>
                  <td>
                    <button onClick={() => fetchResult(job)}>Check</button>
                  </td>
                  <td>
                    {job.status === "succeeded" ||
                    job.status === "failed" ||
                    job.status === "cancelled" ? (
                      <button onClick={() => aiStore.removeJob(job)}>
                        Del
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to cancel this job?"
                            )
                          ) {
                            cancelJob(job);
                          }
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </td>
                  <td>
                    <button onClick={() => displayLog(job)}>View</button> /
                    <button onClick={() => importScript(job)}>Recall</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="toolbar__block">
            <JsonEditor
              data={aiStore.videoBatchScript}
              setData={(data: JsonData) => {
                aiStore.setVideoBatchScript(data);
              }}
              rootName="script"
              enableClipboard={false}
              restrictEdit={true} // 全てカスタムノードで編集機能を実装するので標準の編集機能はオフにする
              restrictAdd={true}
              restrictDelete={true}
              showCollectionCount={false}
              restrictTypeSelection={true}
              // theme="monoDark"
              rootFontSize={14}
              customNodeDefinitions={customNodeDefinitions}
            />
          </div>

          <div className="toolbar__block">
            <JsonEditor
              data={processLog}
              theme="monoDark"
              rootName="log"
              restrictEdit={true}
              restrictDrag={true}
              restrictDelete={true}
              restrictAdd={true}
              enableClipboard={false}
              rootFontSize={14}
            />
          </div>
        </>
      ) : (
        <div>Not available to free users</div>
      )}
    </div>
  );
});

export default ToolbarBatch;
