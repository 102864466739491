import React from "react";

import { observer } from "mobx-react-lite";

import LayerControl from "./LayerControl";
import RedoButton from "./RedoButton";
import UndoButton from "./UndoButton";
import ZoomControl from "./ZoomControl";
import useStore from "../../hooks/useStore";

const Header: React.FC = observer(() => {
  const { canvasStore } = useStore();
  return (
    <header
      className={`header ${
        canvasStore.useWiderToolbar() ? "canvas-toolbar-wider" : ""
      }`}
    >
      <div className="header__items-group">
        <div className="header__item">
          <UndoButton />
        </div>
        <div className="header__item">
          <RedoButton />
        </div>
      </div>
      <div className="header__items-group">
        <div className="header__item">
          <ZoomControl />
        </div>
      </div>
      <div className="header__items-group">
        <div className="header__item">
          <LayerControl />
        </div>
      </div>
    </header>
  );
});

export default Header;
