import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Gallery, ThumbnailImageProps } from "react-grid-gallery";
import ReactCrop, { Crop, PixelCrop, PercentCrop } from "react-image-crop";

import { observer } from "mobx-react-lite";
import { BsArrowsFullscreen as PreviewFullScreen } from "react-icons/bs";

import UploadButton from "./Header/UploadButton";
import Lightbox from "./LightBox";
import { FavoriteButton, DeleteButton } from "./PreviewThumbnail";
import { CROP_ASPECT_RATIO_LIMIT } from "../helpers/imageManipulator";
import useStore from "../hooks/useStore";
import { AiStore } from "../stores/aiStore";
import "react-image-crop/dist/ReactCrop.css";

const ImageComponent = observer(
  ({
    thumbnailImageProps,
    aiStore,
  }: {
    thumbnailImageProps: ThumbnailImageProps;
    aiStore: AiStore;
  }) => {
    const { key, src, style } = thumbnailImageProps.imageProps;

    const toggleFavoriteWrapper = (event: React.MouseEvent) => {
      event.stopPropagation();
      aiStore.toggleFavorite(key as number);
    };

    const deleteWrapper = (event: React.MouseEvent) => {
      event.stopPropagation();
      aiStore.deleteFromHistory(key as number);
    };

    const isDeleted = aiStore.isDeleted(key as number);
    if (isDeleted) {
      return (
        <div
          style={{ backgroundColor: "black", width: "100%", height: "100%" }}
        ></div>
      );
    }

    return (
      <div style={{ ...style, textAlign: "center" }}>
        <img src={src} style={style} />
        <FavoriteButton
          isFavorite={aiStore.isFavorite(key as number)}
          onClick={toggleFavoriteWrapper}
        />
        <DeleteButton onClick={deleteWrapper} />
      </div>
    );
  }
);

const Preview: React.FC = observer(() => {
  const { uiStore, aiStore } = useStore();

  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const placeholderImageUrl =
    "https://placehold.co/1000x1000?text=Drop+your+image";
  // "https://placehold.jp/30/aaaaaa/ffffff/1000x1000.png?text=Drop+your+image";

  const setImage = (files: File[]) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageUrl = String(reader.result);
      aiStore.setTargetImageData(imageUrl, files[0].name);
    };
    reader.readAsDataURL(files[0]);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setImage(acceptedFiles);
  }, []);
  const { getRootProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const setLightboxIndexByStep = (index: number, step: number) => {
    const modAlwaysPositive = (n: number, m: number) => ((n % m) + m) % m;
    let i = index;
    for (;;) {
      i = modAlwaysPositive(i + step, aiStore.imageHistory.length);
      if (!aiStore.isDeleted(i)) {
        if (i !== index) {
          setLightboxIndex(i);
        }
        return i;
      }
    }
  };

  const handleSelect = (index: number) => {
    aiStore.setTargetImageDataFromHistory(index);
  };
  const handleClick = (index: number) => {
    if (!aiStore.isDeleted(index)) {
      setLightboxIndex(index);
    }
  };

  const setCropWithAspectRatioConstraint = useCallback(
    (crop: PixelCrop, percentageCrop: PercentCrop) => {
      if (
        (crop.width > crop.height &&
          crop.width / crop.height > CROP_ASPECT_RATIO_LIMIT) ||
        (crop.width < crop.height &&
          crop.height / crop.width > CROP_ASPECT_RATIO_LIMIT)
      ) {
        return;
      }
      setCrop(crop);
    },
    []
  );

  const handleCropComplete = useCallback((c: PixelCrop) => {
    aiStore.setCroppedArea(c);
  }, []);

  const setCropForBodyPartAndStartEdit = async (bodyRegion: string) => {
    if (!imgRef.current) return;
    const c = await aiStore.setCropForBodyRegion(imgRef.current, bodyRegion);
    if (c) {
      setCrop(c);
    }
    await aiStore.setImageOnCanvas(imgRef.current, bodyRegion, true);
  };

  return (
    <section className="preview">
      <div className="preview__header">
        <h4 className="preview__title">Preview</h4>
        {uiStore.inProgress && <div className="spinner"></div>}
        <div className="preview__item preview__push">
          <UploadButton onFileSelected={setImage} />
        </div>
      </div>

      <div {...getRootProps({ className: "dropzone" })}>
        <ReactCrop
          crop={crop}
          onChange={setCropWithAspectRatioConstraint}
          onComplete={handleCropComplete}
          minWidth={50}
          minHeight={50}
          style={{
            margin: "10px",
          }}
        >
          <img
            ref={imgRef}
            src={aiStore.imageData || placeholderImageUrl}
            className="preview"
            alt=""
          ></img>
        </ReactCrop>
      </div>
      <div className="preview__option_wrapper">
        <div
          className={`preview__option preview__option__option1 ${
            aiStore.imageData ? "preview__option_active" : ""
          }`}
          onClick={async () => {
            if (!imgRef.current) return;
            await aiStore.setImageOnCanvas(imgRef.current);
          }}
        >
          <p>Edit selection </p>
        </div>

        <div className="preview__option_stack_wrapper">
          <div
            className={`preview__option preview__option__stacked ${
              aiStore.imageData ? "preview__option_active" : ""
            }`}
            onClick={async () => {
              await setCropForBodyPartAndStartEdit("face");
            }}
          >
            <p>Edit face</p>
          </div>
          <div
            className={`preview__option preview__option__stacked ${
              aiStore.imageData ? "preview__option_active" : ""
            }`}
            onClick={async () => {
              await setCropForBodyPartAndStartEdit("body");
            }}
          >
            <p>Edit body</p>
          </div>
        </div>
        <div
          className={`preview__option preview__option__option2 ${
            aiStore.imageData ? "preview__option_active" : ""
          }`}
          onClick={() => {
            handleClick(aiStore.targetImageIndex);
          }}
        >
          <p>
            <PreviewFullScreen size={30} />
          </p>
        </div>
      </div>

      <Gallery
        images={aiStore.imageHistory.toArray()}
        thumbnailImageComponent={(props: ThumbnailImageProps) => (
          <ImageComponent thumbnailImageProps={props} aiStore={aiStore} />
        )}
        onSelect={handleSelect}
        onClick={handleClick}
      />
      <Lightbox
        images={aiStore.imageHistory}
        currentIndex={lightboxIndex}
        setCurrentIndex={setLightboxIndex}
        setLightboxIndexNext={(index: number) =>
          setLightboxIndexByStep(index, 1)
        }
        setLightboxIndexPrev={(index: number) =>
          setLightboxIndexByStep(index, -1)
        }
        aiStore={aiStore}
      />
    </section>
  );
});

export default Preview;
